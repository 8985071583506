.ascii-glitch {
  font-family: 'IBM Plex Mono', Menlo, monospace;
  font-weight: 700;
  cursor: default;
  
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 14px;
  }
  
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 18px;
  }

  @media (min-width: 2000px) {
    font-size: 32px;
    line-height: 36px;
  }
}
